import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
// Set your APP_ID
export const APP_ID = process.env.PYLON_APP_ID;

const BLACKLISTED_ROUTES = ["/scheduling-extension"];

// Loads Pylon with the snippet
export const loadPylon = () => {
  (function () {
    var e = window;
    var t = document;
    var n = function () {
      n.e(arguments);
    };
    n.q = [];
    n.e = function (e) {
      n.q.push(e);
    };
    e.Pylon = n;
    var r = function () {
      var e = t.createElement("script");
      e.setAttribute("type", "text/javascript");
      e.setAttribute("async", "true");
      e.setAttribute("src", "https://widget.usepylon.com/widget/APP_ID");
      var n = t.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(e, n);
    };
    if (t.readyState === "complete") {
      r();
    } else if (e.addEventListener) {
      e.addEventListener("load", r, false);
    }
  })();
};

// Initialize Pylon with user settings
export const initializePylon = (options = {}) => {
  if (window && APP_ID) {
    window.pylon = {
      chat_settings: {
        app_id: APP_ID,
        email: options.email,
        name: options.name || `Anonymous ${options.org.name} User`,
        // Add email_hash if you implement identity verification
        // email_hash: options.email_hash,
      },
    };
  }
};

export const PylonProvider = ({ children }) => {
  const router = useRouter();
  const session = useSession();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && session?.data) {
      const isBlacklisted = BLACKLISTED_ROUTES.some((route) =>
        router.pathname.startsWith(route)
      );

      if (!isLoaded && !isBlacklisted && session?.data) {
        loadPylon();
        setIsLoaded(true);
        initializePylon({
          name: session?.data?.user?.name,
          email: session?.data?.user?.email,
          org: session?.data?.user?.org,
        });
      }
    }
  }, [session, router.pathname]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window !== "undefined" && !isLoaded && session?.data) {
        const isBlacklisted = BLACKLISTED_ROUTES.some((route) =>
          url.startsWith(route)
        );

        if (!isBlacklisted) {
          loadPylon();
          setIsLoaded(true);
          initializePylon({
            name: session?.data?.user?.name,
            email: session?.data?.user?.email,
            org: session?.data?.user?.org,
          });
        }
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [isLoaded, session]);

  return children;
};
