import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Session } from "next-auth";
import { SessionProvider, useSession } from "next-auth/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-quill/dist/quill.snow.css";
import { initializeClient } from "../api-client/client";
import { initializeClient as initializeOrvalClient } from "../api-client/orval/mutator/apiClient";
import { AuthGuard } from "../components/AuthGuard";
import ErrorFallback from "../components/ErrorFallback";
import { PylonProvider } from "../utils/pylon";
import { candidiateTheme } from "../utils/theme";
import * as Sentry from "@sentry/nextjs";
import "react-day-picker/dist/style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useRouter } from "next/router";
import "./global.css";
import { usePostHog } from "../utils/hooks/usePostHog";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

function App({ Component, ...pageProps }: any) {
  const session = useSession();
  const [initialized, setInitialized] = useState(false);
  const [identifyCalled, setIdentifyCalled] = useState(false);
  const [posthog, setPosthog] = useState<any>();

  useEffect(() => {
    if (session.status === "authenticated" && !initialized) {
      const token = (session?.data as any).accessToken;
      initializeClient({ token });
      initializeOrvalClient({ token });
      setInitialized(true);
    }
  }, [session, initialized]);

  usePostHog(publicRuntimeConfig.ADMIN_POSTHOG_KEY, {
    api_host: publicRuntimeConfig.POSTHOG_URL,
    loaded: (_posthog) => {
      if (process.env.NODE_ENV === "development") {
        _posthog.opt_out_capturing();
      }
      if (!posthog) {
        setPosthog(_posthog);
      }
    },
  });

  useEffect(() => {
    if (
      posthog &&
      session.status === "authenticated" &&
      session.data &&
      !identifyCalled
    ) {
      posthog.identify((session.data as any).user.id);
      posthog.group("organization", (session.data as any).user.org.id, {
        name: (session.data as any).user.org.name,
        id: (session.data as any).user.org.id,
      });
      setIdentifyCalled(true);
    }
  }, [posthog, session, identifyCalled]);

  if (!initialized && session.status === "authenticated") {
    return null;
  }

  if (Component.requireAuth) {
    return (
      <AuthGuard
        authRoles={Component.authRoles}
        rerouteDisabled={Component.rerouteDisabled}
        UnAuthedComponent={Component.UnAuthedComponent}
      >
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <Component {...pageProps} />
        </Sentry.ErrorBoundary>
      </AuthGuard>
    );
  } else {
    return <Component {...pageProps} />;
  }
}

function AppWrapper({ Component, pageProps }: AppProps<{ session: Session }>) {
  const theme = extendTheme(candidiateTheme);
  const [isAppReady, setAppReady] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (router.isReady && !isAppReady) {
      setAppReady(true);
    }
  }, [router.isReady]);

  if (!isAppReady) {
    return null;
  }

  return (
    <SessionProvider session={pageProps.session}>
      <PylonProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
              <Head>
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16.png"
                />
                <title>candidate.fyi</title>
              </Head>
              <App Component={Component} {...pageProps} />
              {/* {process.env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )} */}
            </DndProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </PylonProvider>
    </SessionProvider>
  );
}

export default AppWrapper;
